import { isV1BE } from 'helpers/envUtils';

export const defaultRouteConfig = {
  homePage: '/dashboard',
  menu: [
    {
      path: '/product',
      name: 'menuConfig:productConfig:product',
      children: [
        {
          path: '/product/list',
          name: 'menuConfig:productConfig:list',
        },
        {
          path: '/product/insurance',
          name: 'Điều khoản bổ sung',
        },
        {
          path: '/product/setting',
          name: 'menuConfig:productConfig:settingConfig:setting',
          children: [
            {
              path: '/product/setting/product-group',
              name: 'Nhóm sản phẩm',
            },
          ],
        },
      ],
    },
    {
      path: '/marketing',
      name: 'menuConfig:marketingConfig:marketing',
      children: [
        {
          path: '/marketing/partner',
          name: 'menuConfig:marketingConfig:partner',
        },
        {
          path: '/marketing/campaign',
          name: 'menuConfig:marketingConfig:campaign',
        },
      ],
    },
    {
      path: '/sell',
      name: 'menuConfig:sellConfig:sell',
      children: [
        {
          path: '/sell/active',
          name: 'menuConfig:sellConfig:active',
        },
        {
          path: '/sell/unallocated',
          name: 'menuConfig:sellConfig:unallocated',
        },
        {
          path: '/sell/following',
          name: 'menuConfig:sellConfig:following',
        },
        {
          path: '/sell/blacklist',
          name: 'menuConfig:sellConfig:blackList',
        },
        {
          path: '/sell/close',
          name: 'menuConfig:sellConfig:closeLead',
        },
        // {
        //   path: '/sell/claim',
        //   name: 'menuConfig:sellConfig:claim',
        //   disabled: isV1BE(),
        // },
      ],
    },
    {
      path: '/expertise',
      name: 'menuConfig:expertiseConfig:expertise',
      children: [
        {
          path: '/expertise/expertise-list',
          name: 'menuConfig:expertiseConfig:expertise-list',
        },
        {
          path: '/expertise/approval',
          name: 'menuConfig:expertiseConfig:approval',
        },
        {
          path: '/expertise/field-appraisal',
          name: 'menuConfig:expertiseConfig:fieldAppraisal',
        },
        {
          path: '/expertise/supplemental-documentation',
          name: 'menuConfig:expertiseConfig:supplementalDocumentation',
        },
        {
          path: '/expertise/disbursement-tracking',
          name: 'menuConfig:expertiseConfig:disbursementTracking',
        },
      ],
    },
    {
      path: '/customer',
      name: 'menuConfig:customerConfig:customer',
      children: [
        {
          path: '/customer/manage',
          name: 'menuConfig:customerConfig:manage',
        },
      ],
    },
    {
      path: '/report',
      name: 'menuConfig:reportConfig:report',
      children: [
        {
          path: '/report/dashboard',
          name: 'menuConfig:reportConfig:dashboard',
        },
        {
          path: '/report/activity-report',
          name: 'menuConfig:reportConfig:activity-report',
        },
        {
          path: '/report/call-report',
          name: 'menuConfig:reportConfig:call-report',
        },
        {
          path: '/report/efficient-partner',
          name: 'menuConfig:reportConfig:efficient-partner',
        },
        {
          path: '/report/staff-report',
          name: 'menuConfig:reportConfig:staff-report',
        },
        {
          path: '/report/department-report',
          name: 'menuConfig:reportConfig:department-report',
        },
        {
          path: '/report/business-result',
          name: 'menuConfig:reportConfig:business-result',
        },
        {
          path: '/report/efficient-convert-lead',
          name: 'menuConfig:reportConfig:efficient-convert-lead',
        },
        {
          path: '/report/distribute-lead',
          name: 'menuConfig:reportConfig:distribute-lead',
        },
      ],
    },
    {
      path: '/kpi',
      name: 'menuParent:kpi',
    },
    {
      path: '/calendar',
      name: 'menuParent:calendar',
    },
    {
      path: '/settings',
      name: 'menuParent:settings',
    },
  ],
  pages: [
    {
      path: '/calendar',
      name: 'Lịch làm việc',
      resource: 'svc-calendar',
      roles: ['u_event'],
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
    },
    {
      path: '/customer',
      name: 'Khách hàng',
    },
    {
      path: '/customers/:id',
    },
    {
      path: '/customer/manage',
      name: 'Danh sách khách hàng',
    },
    {
      path: '/expertise',
      name: 'Thẩm định',
    },
    {
      path: '/expertise/expertise-list',
      name: 'Danh sách thẩm định',
    },
    {
      path: '/expertise/approval',
      name: 'Phê duyệt',
    },
    {
      path: '/expertise/field-appraisal',
      name: 'Thẩm định thực địa',
    },
    {
      path: '/expertise/supplemental-documentation',
      name: 'Bổ sung hồ sơ',
    },
    {
      path: '/expertise/disbursement-tracking',
      name: 'Theo dõi giải ngân',
    },
    {
      path: '/kpi',
      name: 'Kpi',
    },
    {
      path: '/marketing',
      name: 'Marketing',
    },
    {
      path: '/marketing/partner',
      name: 'Đại lý/Đối tác',
      resource: 'svc-lead',
      roles: ['r:partner:view-list'],
    },
    {
      path: '/marketing/partner/:id',
    },
    {
      path: '/marketing/campaign',
      name: 'Chiến dịch',
      resource: 'svc-lead',
      roles: ['r:campaign:view-list'],
    },
    {
      path: '/marketing/campaign/:code',
    },
    {
      path: '/product',
      name: 'Sản phẩm',
    },
    {
      path: '/product/list',
      name: 'menuConfig:productConfig:list',
      resource: 'svc-product',
      roles: ['r:product:view-list'],
    },
    {
      path: '/product/insurance',
      name: 'Điều khoản bổ sung',
      resource: 'svc-product',
      roles: ['r:product:view-list'],
    },
    {
      path: '/product/setting',
      name: 'Cài đặt',
      resource: 'svc-product',
      roles: ['r:product-group:view-list'],
    },
    {
      path: '/product/setting/product-group',
      name: 'Nhóm sản phẩm',
      resource: 'svc-product',
      roles: ['r:product-group:view-list'],
    },
    {
      path: '/report',
      name: 'Báo cáo',
    },
    {
      path: '/report/dashboard',
      name: 'Tổng quan',
    },
    {
      path: '/report/activity-report',
      name: 'Tác nghiệp',
      resource: 'svc-lead',
      roles: ['r:activity:view-report'],
    },
    {
      path: '/report/call-report',
      name: 'Cuộc gọi',
      resource: 'svc-lead',
      roles: ['r:activity:view-report'],
    },
    {
      path: '/report/efficient-partner',
      name: 'Đại lý/Đối tác',
      resource: 'svc-lead',
      roles: ['r:partner:report'],
    },
    {
      path: '/report/staff-report',
      name: 'Nhân viên',
      resource: 'svc-lead',
      roles: ['r:disbursement-report:view-owner'],
    },
    {
      path: '/report/department-report',
      name: 'Phòng ban',
      resource: 'svc-lead',
      roles: ['r:disbursement-report:view-group'],
    },
    {
      path: '/report/business-result',
      name: 'Kết quả kinh doanh',
      resource: 'svc-lead',
      roles: ['r:disbursement-report:view'],
    },
    {
      path: '/report/efficient-convert-lead',
      name: 'Chuyển đổi lead',
      resource: 'svc-lead',
      roles: ['r:conversion-report:view'],
    },
    {
      path: '/report/distribute-lead',
      name: 'Phân bổ lead',
      resource: 'svc-lead',
      roles: ['r:assign-report:view'],
    },
    {
      path: '/sell',
      name: 'Bán hàng',
    },
    {
      path: '/sell/lead',
      name: 'Lead',
      resource: 'svc-lead',
      roles: ['r:lead:view-list'],
    },
    {
      path: '/sell/lead/:id',
    },
    {
      path: '/sell/active',
      name: 'Lead Active',
      resource: 'svc-lead',
      roles: ['r:lead:view-list'],
    },
    {
      path: '/sell/active/:id',
    },
    {
      path: '/sell/unallocated',
      name: 'Lead chưa phân bổ',
      resource: 'svc-lead',
      roles: ['r:lead:view-list'],
    },
    {
      path: '/sell/unallocated/:id',
    },
    {
      path: '/sell/following',
      name: 'Lead theo dõi',
      resource: 'svc-lead',
      roles: ['r:lead:view-list'],
    },
    {
      path: '/sell/following/:id',
    },
    {
      path: '/sell/blacklist',
      name: 'Blacklist Lead',
      resource: 'svc-lead',
      roles: ['r:lead:view-list'],
    },
    {
      path: '/sell/blacklist/:id',
    },
    {
      path: '/sell/close',
      name: 'Lead đã dóng',
      resource: 'svc-lead',
      roles: ['r:lead:view-list'],
    },
    {
      path: '/sell/close/:id',
    },
    {
      path: '/sell/claim',
      name: 'Claim',
      resource: 'svc-lead',
      roles: ['r:lead:view-list'],
    },
    {
      path: '/sell/claim/:id',
    },
    {
      path: '/settings',
      name: 'Cài đặt',
    },
    {
      path: '/settings/company-info',
      name: 'menu:settings:company-info',
    },
    {
      path: '/settings/basicdata/:tabName',
      name: 'menu:settings:basicdata',
    },
    {
      path: '/settings/flowmanagement/:tabName',
      name: 'menu:settings:flowmanagement',
    },
    {
      path: '/settings/leadmanagement/kpi',
      name: 'menu:settings:leadmanagement',
      resource: 'svc-lead',
      roles: ['r:kpi:view'],
    },
    {
      path: '/settings/leadmanagement/:tabName',
      name: 'menu:settings:leadmanagement',
    },
    {
      path: '/settings/systemmanagement/calendar',
      name: 'menu:settings:systemmanagement',
      resource: 'svc-calendar',
      roles: ['r:calendar:setting'],
    },
    {
      path: '/settings/systemmanagement/:tabName',
      name: 'menu:settings:systemmanagement',
    },
    {
      path: '/settings/default-setting',
      name: 'menu:settings:company-info',
    },
    {
      path: '/account/logout',
    },
  ],
};
