import './init';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// import { createRoutesFromChildren, matchRoutes } from 'react-router-dom';
// import * as Sentry from '@sentry/react';
import { getLoggedInUser } from 'helpers/authUtils';
import { store } from './redux/store';
import App from './App';
// import packageInfo from '../package.json';

// import reportWebVitals from './reportWebVitals';
// import { registerFcm } from './firebase/fcmRegistration';

import 'assets/scss/app.scss';

import './index.css';

const isProduction = import.meta.env.PROD;

// if (isProduction) {
//   // eslint-disable-next-line no-console
//   console.log = () => {};
//   console.warn = () => {};
//   console.error = () => {};
// }

// const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
// const currentLanguage = getLanguage();

// Sentry.init({
//   dsn: 'https://39a89a6d797086559df6357917c7d1ca@sentry.nexusti.cloud/4',
//   release: packageInfo.version,
//   enabled: isProduction,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//     Sentry.reactRouterV7BrowserTracingIntegration({
//       useEffect,
//       useLocation,
//       useNavigationType,
//       createRoutesFromChildren,
//       matchRoutes,
//     }),
//   ],
//   // Tracing
//   tracesSampleRate: 0.1, //  Capture 10% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// const user = getLoggedInUser();

// Sentry.setUser(user ? { username: user.username } : null);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

// Background FCM notifications
// registerFcm();
