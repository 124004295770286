import { Middleware, MiddlewareAPI, configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { getLoggedIn, setLoggedIn, setLoggedInUser } from 'helpers/authUtils';
import { isExpiredToken } from 'helpers/api';
import { cp } from 'helpers/chainParse';
import toast from 'react-hot-toast';
import rootReducer from './reducers';
import sagas from './sagas';
import appMiddlewares from './middleware';

const sagaMiddleware = createSagaMiddleware();

const buffer = [];

const autoRefreshTokenMiddleware: Middleware = (store) => (next) => (action) => {
  buffer.push(action);

  // if (action.payload == 'TypeError: Failed to fetch') {
  const loggedIn: any = getLoggedIn();

  if (loggedIn && Object.keys(loggedIn).length && isExpiredToken()) {
    setLoggedInUser(null);
    setLoggedIn(null);

    window.location.href = '/account/login';
    // navigate('/account/login');
    // store.dispatch(refreshToken());
  } else {
    next(action);
  }
};

const rtkQueryErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { payload } = action;
    if (cp(payload, ['status']) !== 403) {
      toast.error(cp(payload, ['data', 'message'], 'Có lỗi xảy ra'));
    }
  }

  return next(action);
};

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//   // Optionally pass options listed below
// });

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // thunk: false,
    }).concat([...appMiddlewares, sagaMiddleware, rtkQueryErrorMiddleware, autoRefreshTokenMiddleware]),
  // enhancers: (getDefaultEnhancers) => {
  //   return getDefaultEnhancers().concat(sentryReduxEnhancer);
  // },
});

sagaMiddleware.run(sagas);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
