import { PRODUCT_GROUP_VIEW_LIST, PRODUCT_SVC, PRODUCT_VIEW_LIST } from 'constants/roles';
import { DiamondOutlined } from '@mui/icons-material';
import { NxRoute } from './types';

const ListProduct = lazy(() => import('pages/admin/product-management/list-product'));
const ProductSetting = lazy(() => import('pages/admin/product-management/setting/index'));
const InsuranceProduct = lazy(() => import('pages/admin/product-management/insurance-product'));

const productRoutes: NxRoute = {
  path: '/product',
  Component: () => <Navigate to="/product/insurance" />,
  name: 'menuConfig:productConfig:product',
  icon: DiamondOutlined,
  children: [
    {
      path: '/product/list',
      name: 'menuConfig:productConfig:list',
      Component: ListProduct,
      resource: PRODUCT_SVC,
      roles: [PRODUCT_VIEW_LIST],
    },
    {
      path: '/product/insurance',
      name: 'Điều khoản bổ sung',
      Component: InsuranceProduct,
      resource: PRODUCT_SVC,
      roles: [PRODUCT_VIEW_LIST],
    },
    {
      path: '/product/setting',
      name: 'menuConfig:productConfig:settingConfig:setting',
      Component: () => <Navigate to="/product/setting/product-group" />,
      resource: PRODUCT_SVC,
      roles: [PRODUCT_GROUP_VIEW_LIST],
      children: [
        {
          path: '/product/setting/product-group',
          name: 'menuConfig:productConfig:settingConfig:productGroup',
          Component: ProductSetting,
          resource: PRODUCT_SVC,
          roles: [PRODUCT_GROUP_VIEW_LIST],
        },
      ],
    },
  ],
};

export default productRoutes;
